@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;900&family=Poppins:wght@200;400;700&display=swap");

p {
  font-size: 1.125rem;
}

a,
a:hover {
  text-decoration: none;
  color: #000;
}

h3.toper {
  font-size: 1rem;
  letter-spacing: 0.6rem;
  text-transform: uppercase;
}

h2.title {
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 900;
}

h3.title {
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 700;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a.btn1,
a.btn1:hover {
  text-decoration: none;
  color: #fff;
}
.btn1 {
  padding: 0.5rem 2rem;
  background: #d89c4e;
  color: #fff;
  border: 0;
  border-radius: 500px;
  margin-top: 1rem;
  margin-right: 1rem;
}

.btn2 {
  padding: 0.5rem 2rem;
  background: rgb(6 22 91);
  color: #fff;
  border: 0;
  border-radius: 500px;
  margin-top: 1rem;
  margin-right: 1rem;
}
.btn3 {
  padding: 0.5rem 2rem;
  background: #fff;
  border: 1px solid rgb(6 22 91);
  color: rgb(6 22 91);
  border-radius: 500px;
  margin-top: 1rem;
  margin-right: 1rem;
}
.btn4 {
  padding: 0.5rem 0.5rem;
  background: #fff;
  border: 0px solid rgb(6 22 91);
  color: #090909;
  border-radius: 500px;
  margin-top: 1rem;
  margin-right: 1rem;
}
.btn5 {
  padding: 0.75rem 2rem;
  background: rgb(6 22 91);
  color: #fff;
  border: 0;
  border-radius: 6px;
  margin-top: 1rem;
  margin-right: 1rem;
  width: 250px;
}

button.menuOpen {
  background: none;
  border: 0;
  position: fixed;
  left: 0.5rem;
  top: 0.75rem;
}
button.menuOpen img {
  height: 40px;
}
.mobile-nav {
  transition: all 0.3s;
}
.mobile-nav ul {
  display: block !important;
  height: calc(100vh - 70px);
  justify-content: unset !important;
  width: 70%;
  float: left;
  padding: 4rem 1.5rem;
  margin: 0;
  position: fixed;
  z-index: -1;
  top: 70px;
  background: #08144b;
}
.mobile-nav ul li {
  background: none !important;
  text-align: left;
  margin-bottom: 1.75rem;
}
.mobile-nav ul li a {
  color: #fff;
  font-size: 1.125rem;
  background: #08185c;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}
.mobile-nav ul li.spl a {
  background: #d89c4e !important;
  color: #08185c !important;
}

.mobile-nav.animate {
  animation: navAnimation 0.5s ease-in-out;
}

#Explore {
  scroll-margin-top: 100px;
}

.hider {
  height: 100vh;
  width: 100%;
  background: #ffffff;
  position: fixed;
  top: 0;
  z-index: -2;
  opacity: 0.01;
}
ul.lister li {
  background: rgb(247 242 249);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}

ul.lister img {
  height: 50px;
  margin-right: 1rem;
  float: left;
}

ul.lister p {
  margin-top: 0.5rem;
}

.spl a {
  background: rgb(6 22 91) !important;
  color: #fff !important;
}

header {
  position: fixed;
  width: 100%;
  padding: 1rem;
  z-index: 999;
  top: 0;
  background: #ffffff;
}

header a.navi img {
  height: 40px;
  margin-right: 1rem;
}

header a.logo img {
  width: 150px;
  float: left;
}

.nav.mob ul li a.call {
  padding: 0;
  background: #ffffff00 !important;
}

.nav.mob ul li img {
  width: 30px;
  transform: translateY(-5px);
}

header a.logo,
header a.navi {
  float: left;
  width: fit-content;
}

.nav.mob {
  width: 50%;
  transform: translateY(-10px);
}

.mobIcons {
  width: 50%;
  float: left;
  display: flex;
}

header .nav ul {
  list-style: none;
  margin: 0;
  padding: 1rem 0;
  display: flex;
  width: 100%;
  justify-content: end;
}

header .nav ul li a {
  padding: 0.5rem 1.5rem;
  background: rgb(245, 245, 245);
  margin-left: 1rem;
  border-radius: 500px;
}

.heroSection {
  background: #fff;
  padding: 5rem;
  margin-top: 3rem;
}

.heroSection .row {
  background: rgb(6 22 91);
  min-height: 70vh;
  border-radius: 10px;
}

.vertical-center-70 {
  min-height: 70vh;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mainTxt {
  padding: 5rem;
}

.mainTxt h1 {
  font-size: 3.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  color: white;
}

.mainTxt h1 span {
  color: #d89c4e;
}

.media img {
  width: 80%;
  float: right;
}

.serviceSection .item {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 50px #dcdee2, 0px 0px 50px #ffffff;
  padding: 1.5rem;
}
.serviceSection .row .col-12 {
  display: flex;
}
.serviceSection .item img {
  height: 75px;
}

.serviceSection .item h3 {
  font-size: 1.5rem;
}

.aboutSection {
  padding: 10rem 1rem;
}

.explainSection {
  padding: 5rem 1rem;
  background: no-repeat bottom left;
  background-size: contain;
}

.explainSection .hsImg img {
  width: 80%;
  float: left;
}

.explainSection.even {
  background: no-repeat center right;
  background-size: contain;
}

.explainSection.even .hsImg img {
  float: right;
}

.contactBarSection .row .txt {
  color: #fff;
  background: rgb(6 22 91);
  padding: 5rem;
  margin-bottom: 5rem;
  border-radius: 5px;
}

.projectSection {
  display: none;
  padding: 10rem 5rem 5rem 5rem;
  background: center top no-repeat;
}

.projectSection .item {
  background-color: rgb(234 247 236);
  padding: 1rem;
  border-radius: 5px;
  background-size: cover;
  position: relative;
  overflow: hidden;
  height: 250px;
}
.projectSection .item .cont {
  position: absolute;
  z-index: 2;
  bottom: 0;
  color: #ffffff;
  background: linear-gradient(
    0deg,
    rgba(113, 35, 158, 1) 0%,
    rgba(113, 35, 158, 0) 100%
  );
  padding: 1rem;
  left: 0;
  width: 100%;
}
.projectSection .item h3 {
  font-size: 1.5rem;
}
.partnerSection {
  padding: 5rem 0rem;
}
.partnerSection .swiper-slide {
  text-align: center;
}
.partnerSection img {
  height: 75px;
  margin: auto;
}
.footerSection {
  border-top: 1px solid #eaf7ec;
  padding: 3rem 1rem;
}

.footerSection ul img {
  height: 50px;
  display: block;
}

.onlyMobile {
  display: none;
}

.NavFooter {
  position: fixed;
  bottom: 0.5rem;
  background: rgb(6 22 91);
  z-index: 999;
  width: 90%;
  left: 5%;
  padding: 0.75rem;
  border-radius: 5px;
}
.NavFooter ul {
  list-style: none;
  display: flex;
  justify-content: center;
}
.NavFooter ul li a {
  color: #fff;
  padding: 0.5rem 1rem;
}

.form-wrap {
  padding: 0 1rem;
}
.form-wrap input,
.form-wrap select,
textarea#comments {
  border: 0px;
  border-radius: 0;
  background: rgb(247 242 249);
  font-size: 1.125rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #000;
}
.form-wrap label {
  font-size: 0.9rem;
  margin-bottom: 0.5em;
  font-weight: 500;
}

.heroSection.page .row {
  height: unset !important;
  min-height: unset;
}
.pageInSection {
  padding: 0 1.5rem;
}
.pageInSection img {
  width: 100%;
  border-radius: 5px;
  height: auto;
}
iframe {
  border-radius: 5px;
}
.Placeholder {
  transform: scaleX(95%);
  margin-left: 1rem;
}
.Placeholder .image {
  height: 180px;
  width: 87.5vw;
  border-radius: 10px;
  object-fit: cover;
  background: #f1f1f1;
  margin-bottom: 0.75rem;
  background: linear-gradient(-45deg, #f4f4f4f4, #f1f1f1, #c9c9c9c9, #f1f1f1);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}
.Placeholder .image.small {
  height: 120px;
}
.Placeholder h3 {
  height: 25px;
  border-radius: 50px;
  width: 87.5vw;
  background: #f1f1f1;
  background: linear-gradient(-45deg, #f4f4f4f4, #f1f1f1, #c9c9c9c9, #f1f1f1);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}

.Placeholder .metadata {
  height: 20px;
  border-radius: 50px;
  width: 35vw;
  background: #f1f1f1;
  background: linear-gradient(-45deg, #f4f4f4f4, #f1f1f1, #c9c9c9c9, #f1f1f1);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  margin-left: 0rem;
}

.Placeholder.single h3:last-child {
  width: 70vw;
}

.Placeholder.single .metadata {
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.Placeholder.single .cont p {
  height: 14px;
  border-radius: 50px;
  width: 80vw;
  background: #f1f1f1;
  background: linear-gradient(-45deg, #f4f4f4f4, #f1f1f1, #c9c9c9c9, #f1f1f1);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}
.Placeholder.single .cont p:last-child {
  width: 50vw;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media (max-width: 1200px) {
  body {
    padding: 0.5rem;
  }
  iframe {
    border: 0;
    width: 100%;
    height: 225px;
  }
  h2.title {
    font-size: 1.75rem;
  }
  .onlyWeb {
    display: none;
  }

  .onlyMobile {
    display: block;
  }

  header a.logo img {
    width: 125px;
    margin-left: 50px;
  }

  .heroSection {
    padding: 1rem;
    margin-bottom: 7rem;
    margin-top: 4rem;
  }
  .heroSection .row {
    height: 45vh;
  }
  .mainTxt {
    padding: 2rem;
  }
  .media img {
    width: 60%;
    margin-left: 20%;
    float: unset;
  }

  .mainTxt h1 {
    font-size: 2rem;
  }

  .explainSection {
    margin-bottom: 5rem;
    padding: 1rem 0;
  }

  .explainSection .hsImg img,
  .explainSection.even .hsImg img {
    width: 100%;
  }

  .projectSection,
  .contactBarSection,
  .footerSection {
    padding: 3rem 0;
  }
  .contactBarSection {
    padding: 3rem 0 0 0;
    margin-bottom: -5rem;
    margin-top: -5rem;
  }
  .contactBarSection .row .txt {
    padding: 5rem 1rem;
    margin-bottom: 5rem;
    transform: scale(0.95) !important;
  }
  .projectSection .item h3 {
    font-size: 1.25rem;
  }
  header {
    padding: 1rem 0.5rem;
    transform: translateX(-0.5rem);
    height: 70px;
  }
  .heroSection.page {
    margin-bottom: 1rem;
  }
  .heroSection.page .row {
    min-height: unset !important;
  }
}
@media (max-width: 720px) {
  .media img {
    width: 80%;
    margin-left: 10%;
    float: unset;
  }
}
